import React from 'react'

export const Header = () => {
	return(
		<header>
			<div className="container header-container">
				<div className='logo'>
					mayraCruz
				</div>
				{/* <nav>
					<Link to="/sobre-mi" className="nav-item">Sobre mí</Link>
				</nav> */}
			</div>
		</header>
	)
}